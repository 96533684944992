import CarouselBase from 'nuka-carousel'
import React from 'react'

import styles from './jobs-item.module.css'

export const Job = props => {
  return (
    <a
      href={props.link}
      className={styles.itemWrapper}
      style={{ background: props.color }}
    >
      <div className={styles.jobDate}>{props.date}</div>
      <div className={styles.jobTitle}>{props.title}</div>
      <div className={styles.mealDescription}>{props.description} </div>
    </a>
  )
}

const renderJobs = () => [
  <Job
    key="1"
    title="Production Director"
    description="Máš už v potravinářském průmyslu nebo v FMCG něco za sebou? Žiješ technologiemi? A hodně tě baví ty kolem výrobních procesů? Tak to jsi dost možná ten pravý/á kandidát/ka na pozici Výrobní ředite CZ/SK NutritionPro!"
    date="26.12.2022"
    color="#E3F0FA"
    link="http://jobs.nutritionpro.cz/"
  />,
  <Job
    key="2"
    title="Finanční controller"
    description="Vyrábíme stovky tisíc jídel měsíčně a jsme největší společnost v České republice, která poskytuje personalizované jídelníčky s kompletním servisem. Jestli chceš být součástí nadšeného týmu a rozšířit řady úspěšného FoodTech startupu, tak čti dál! "
    date="26.12.2022"
    color="#FDF9F4"
    link="http://jobs.nutritionpro.cz/"
  />,
  <Job
    key="3"
    title="Sales tým leader"
    description="Máš zkušenosti s vedením obchodního týmu? Baví tě zdravý životní styl? Čti dále!"
    date="26.12.2022"
    color="#EAEFF7"
    link="http://jobs.nutritionpro.cz/"
  />,
  // <Job
  //   key="1"
  //   title="NUTRIČNÍ SPECIALISTA"
  //   description="Do našeho rychle rostoucího týmu hledáme kolegu na pozici Nutriční specialista."
  //   date="12.12.2019"
  //   color="#FDF9F4"
  //   link="/jobs/nutritionist"
  // />,
  <Job
    key="4"
    title="Performance Marketing Specialist"
    description="Do našeho rychle rostoucího týmu hledáme kolegu na pozici Performance Marketing Specialist."
    date="26.12.2022"
    color="#FDF9F4"
    link="http://jobs.nutritionpro.cz/"
  />,
  <Job
    key="5"
    title="Obchodní zástupce"
    description="Jsi obchodník tělem a duší? Baví tě zdravý životní styl? Čti dále!"
    date="26.12.2022"
    color="#EAEFF7"
    link="http://jobs.nutritionpro.cz/"
  />,
  <Job
    key="6"
    title="Zákaznická podpora"
    description="Baví tě komunikace a rád pomáháš lidem? Baví tě zdravý životní styl? Čti dále!"
    date="26.12.2022"
    color="#E3F0FA"
    link="http://jobs.nutritionpro.cz/"
  />,
  <Job
    key="7"
    title="Asistentka generálního ředitele"
    description="Hledáme kolegyni na pozici Asistentka generálního ředitele. Chceš být členem týmu a pravou rukou CEO úspěšného startupu? Baví tě komunikace s lidmi a ráda se vzděláváš? Tak jsi tady správně!"
    date="26.12.2022"
    color="#E3F0FA"
    link="http://jobs.nutritionpro.cz/"
  />,
  <Job
    key="8"
    title="СOO (Chief Operating Officer)"
    description="Nedávno jsme obdrželi investici pro další expanze, a proto hledáme СOO (Chief Operating Officer)."
    date="26.12.2022"
    color="#FDF9F4"
    link="http://jobs.nutritionpro.cz/"
  />,
  <Job
    key="9"
    title="Regionální ředitel pro ČR"
    description="Vyrábíme stovky tisíc jídel měsíčně a jsme největší společnost v České republice, která poskytuje personalizované jídelníčky s kompletním servisem."
    date="26.12.2022"
    color="#F7F4FD"
    link="http://jobs.nutritionpro.cz/"
  />,
  <Job
    key="10"
    title="Event Manager"
    description="Do našeho mladého týmu hledáme šikovného/ou kolegu/yni na pozici Event Manager."
    date="26.12.2022"
    color="#FDF9F4"
    link="http://jobs.nutritionpro.cz/"
  />,
  // <Job
  //   key="5"
  //   title="CEO Assistant"
  //   description="Rosteme, a tak hledáme kolegu/yni na pozici Personal Assistant pro CEO."
  //   date="01.08.2021"
  //   color="#F4F6FF"
  //   link="/jobs/ceo-assistant"
  // />,
  <Job
    key="11"
    title="Kontrolor kvality"
    description="NutritionPro je jedním z nejrychleji rostoucích Foodtech startupů v ČR (500 %+ meziroční růst)."
    date="26.12.2022"
    color="#F7F4FD"
    link="http://jobs.nutritionpro.cz/"
  />,
  <Job
    key="12"
    title="Recruiter"
    description="Nedávno jsme obdrželi investici pro další expanze, a proto hledáme Recruitment Specialistu."
    date="26.12.2022"
    color="#EAEFF7"
    link="http://jobs.nutritionpro.cz/"
  />,
  <Job
    key="13"
    title="Technolog"
    description="Nedávno jsme obdrželi investici pro další expanze, a proto hledáme talentovaného Technologa."
    date="26.12.2022"
    color="#EAEFF7"
    link="http://jobs.nutritionpro.cz/"
  />,

  <Job
    key="14"
    title="Business analyst"
    description="Baví tě komunikace? Máš analytické uvažování? Jsi zkušený v IT a data jsou pro tebe denním chlebem?
    Chceš pracovat pro rychle rostoucí firmu se skvělým týmem?
    Pokud tvoje odpověď byla Ano, možná hledáme právě tebe."
    date="26.12.2022"
    color="#F7F4FD"
    link="http://jobs.nutritionpro.cz/"
  />,
  // <Job title="" description="" date="" color="#FFF" link="" />,
]

const Jobs = () => {
  // const scroll = useSmoothScroll()

  return (
    <div className={styles.content}>
      {/* <Title>Jak vypadá den s NutritionPro</Title> */}

      <div className={styles.meals}>{renderJobs()}</div>
      {/* <CarouselBase
        className={styles.carousel}
        renderBottomCenterControls={() => null}
        renderCenterLeftControls={({ previousSlide }) => (
          <button type="button" onClick={previousSlide}>
            prev
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button type="button" onClick={nextSlide}>
            next
          </button>
        )}
      >
        {renderJobs()}
      </CarouselBase> */}
    </div>
  )
}

export default Jobs
